<template>
  <v-container d-flex justify-center flex-wrap  flat
      height="100vh"
      tile>
        <v-card class="apierror" align-self-xl-center>
            <!-- <div class="logo"><img src='@/assets/img/logo.png'></div> -->
            <v-icon class='iconerror'>error</v-icon>
          <p>Ha habido un error en la conexión al servidor, vuelve a interlo en varios minutos o ponte en contacto con nosotros </p>

            <p>Disculpe las molestias.</p>
                        <button @click="reloadPage" class="button" >Recargar página</button>

        </v-card>
  </v-container>
</template>
<script>
import { gw } from "@/gw.js";
export default {
  name: "Apierror",
  mixins: [gw],
  data: () => ({ }),
  mounted() {
      //console.log("Hola Apierror");
  },
  methods: {
    reloadPage(){
      window.location.reload();
    }
  }
};
</script>