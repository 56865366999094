import Vue from 'vue'
import Vuex from 'vuex'
import { log } from 'util';
import router from './router';

Vue.use(Vuex, router)


export default new Vuex.Store({
    state: {
        _this:this,
        routerviewkey: 0,
        loading: {
            titulo: '',
            estado: false,
            numLoadings: 0
        },
        pagstate:'200', //200, errorapi, updateapk
        auth: null,
        breadcrumbs: [],
        //precioscat: [],
        menulateral: false,
        firma: {
            dataURL: null,
            nombrefirmante: null
        },
        bottomNav: {
            itemactive: null,
            iditemactive: null,
            lastactiveitem:{},
            disabled: false,
            items: [],
            views: [] //Si queremos activar el bottomNav para varias vistas, hay que indicar también el view que pertenece cada item
        },
        lasttoasted:{
            type:null,
            action:null,
            icon:null,
            text:null,
            num:1
        },
        message:{
            active:false,
            icon:null,
            text:'',
            buttons:[{
                text:'',
                to:''
            }]
        }
    },
    mutations: {
        storeLoading(_state, _payload) {
            if (_payload.estado == true) {
                _state.loading.titulo = _payload.titulo
                _state.loading.estado = true
                _state.loading.numLoadings++
            }
            else {
                _state.loading.numLoadings--
                if(_state.loading.numLoadings<=0){
                    _state.loading.estado = false
                    _state.loading.numLoadings = 0
                    _state.loading.titulo = ''
                }
            }
        },
        sumRouterViewKey(_state, _payload){
            _state.routerviewkey += 1;
        },
        setAuth(_state, _payload){
            _state.auth = _payload;
            localStorage.setItem("auth", JSON.stringify(_payload));
        },
        setFirma(_state, _payload){
            _state.firma = {};
            _state.firma.dataURL = _payload['dataURL'];
            _state.firma.nombrefirmante = _payload['nombrefirmante'];

        },
        setBreadcrumbs(_state, _payload){
            _state.breadcrumbs = _payload;
        },
        // setprecioscat(_state, _payload){
        //     _state.precioscat = _payload;
        // },
        setMenu(_state, _payload){
            _state.menulateral = _payload;
        },
        setbottomNav(_state, _payload){
            if(_payload.disabled!=undefined){_state.bottomNav.disabled = _payload.disabled;}
            if(_payload.items!=undefined) {_state.bottomNav.items = _payload.items; _state.bottomNav.iditemactive = 0;}

            /////////////////////////////////////////////////////////Establecer el activo
            //Caso 1 -> No se envia nada, se establece en 0
            _state.bottomNav.iditemactive = 0;
            if(_payload.items!==undefined && _payload.items.length>0){_state.bottomNav.itemactive = _payload.items[0].id;}

            //Caso 2 -> Se envia especificamente por parametro cual queremos empezar o se clica encima
            if(_payload.itemactive!=undefined){ //Establecemos el valor activo
                _state.bottomNav.itemactive = _payload.itemactive;
                _state.bottomNav.lastactiveitem[router.history.current.name] = _payload.itemactive;

                //Establecemos el iditemactive. k necesita ser numerico, y para hacerlo más facil se pasa como texto.
                for(var i = 0; i < _state.bottomNav.items.length; i++){
                    if(_state.bottomNav.items[i].id == _state.bottomNav.itemactive){
                        _state.bottomNav.iditemactive = i;
                        //Si el view del clicado es diferente al actual redireccionar:
                        //To do
                        break;
                    }
                }
            }
            //Caso 3 -> No se envia nada y queremos automatizar la selección al ultimo clicado
            else if(_state.bottomNav.lastactiveitem[router.history.current.name]!==undefined){
                _state.bottomNav.itemactive = _state.bottomNav.lastactiveitem[router.history.current.name];
                //Establecemos el iditemactive. k necesita ser numerico, y para hacerlo más facil se pasa como texto.
                for(var i = 0; i < _state.bottomNav.items.length; i++){
                    if(_state.bottomNav.items[i].id == _state.bottomNav.itemactive){
                        _state.bottomNav.iditemactive = i;
                        break;
                    }
                }
            }
        },
        setMessage(_state, _payload){
            if(_payload.active!=undefined){_state.message.active=_payload.active;}
            if(_payload.icon!=undefined){_state.message.icon=_payload.icon;}
            if(_payload.coloricon!=undefined){_state.message.coloricon=_payload.coloricon;}
            if(_payload.text!=undefined){_state.message.text=_payload.text;}
            if(_payload.buttons!=undefined){_state.message.buttons=_payload.buttons;}
            /*active:true,
            icon:'check_circle',
            coloricon:'#bd0909',
            text:'El PreAlbarán se ha creado correctamente, en breve recibirá un correo electronico con la confirmación.',
            buttons:[{
                text:'cerrar',
                to:''
            }]*/
        },
        setPagState(_state, _payload){
            _state.pagstate = _payload;
        }
    },
    actions: {

    }
})
