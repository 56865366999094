<template> 
    <v-bottom-navigation
      :value="bottomNav.iditemactive"
      color="primary"
      fixed
    >
      <v-btn
              v-for="item in bottomNav.items"
              :key="item.id"
              @click="click_btn(item.id)"
              :disabled="item.disabled">
              
          <span>{{item.text}}</span>
          <v-icon>{{item.icon}}</v-icon>
      </v-btn>
      
    </v-bottom-navigation>
</template>

<script>
 import { gw } from '@/gw.js'

export default {
  mixins: [gw],
  name: 'bottomNav',
  data: () => ({
    /////////////////////Ejemplo
    /*bottomNav({
            //itemactive: 'info', // items.id  -  Default el primero
            disabled: false,
            items: [{
                id: 'info',
                text: 'Info',
                icon: 'info',
                disabled: false
            }
         });
         */
  }),       

  methods: {
      click_btn(id){
            this.setbottomNav({itemactive:id});
      }
  }
}
</script>


