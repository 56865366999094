import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.auth && (_vm.auth.coduser))?_c('div',{staticClass:"toolbar-wrapp"},[_c(VToolbar,{staticClass:"toolbar",attrs:{"fixed":"fixed"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo.png"),"alt":"Inel"},on:{"click":_vm.changelocation}}),_c(VSpacer),_c('label',[_c('router-link',{staticClass:"nav-link",class:{ disabled_button: false },attrs:{"to":"/ordenestrabajo"}},[_c(VBtn,{staticClass:"v-btn--text v-btn--flat",class:(_vm.active_view === 'mrp') ? 'black--text background-transparent' : 'grey--text background-transparent'},[_c(VIcon,{},[_vm._v("work")]),_c('span',[_vm._v(_vm._s(_vm.$t('toolbar_mrp')))])],1)],1)],1),_c('label',[_c(VBtn,{staticClass:"v-btn--text v-btn--flat black--text background-transparent",on:{"click":function($event){_vm.dialogClosseSession=true}}},[_c(VIcon,{},[_vm._v("exit_to_app")])],1)],1),_c(VDialog,{attrs:{"max-width":"290"},model:{value:(_vm.dialogClosseSession),callback:function ($$v) {_vm.dialogClosseSession=$$v},expression:"dialogClosseSession"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("¿Cerrar sesión?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#555555","text":""},on:{"click":function($event){_vm.dialogClosseSession=false}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"#555555","text":""},on:{"click":_vm.click_logout}},[_vm._v("Cerrar sesión")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }