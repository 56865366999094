<template>  
    <div class="breadcrumbs">
      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>keyboard_arrow_right</v-icon>
        </template>
      </v-breadcrumbs> 
    </div>
</template>

<script>
import { mapState } from "vuex"

export default {
    name: 'Breadcrumbs',
    //props: ['breadcrumbs'],
    computed: {
        ...mapState(['breadcrumbs'])
    },
    mounted() {
    }
}
</script>