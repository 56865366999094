<template>
  <v-app  id="app">
    <v-app-bar app v-if="auth && pagstate=='200'">
        <ToolBar/>
    </v-app-bar>
    <v-row no-gutters v-if="pagstate=='200'"> 
      <MenuLateral v-if="auth && menulateral=='true'" />
      <MenuLateralmovil v-if="auth && menulateral=='true'"/>

      <div class="contenido">
        <Message v-if="message.active"/>

        <Breadcrumbs/>
        <router-view :key="routerviewkey"/>
        
          
          <div v-if="loading.estado" class="pantallacompletabloquo"></div>
          <v-dialog
            v-model="loading.estado"
            hide-overlay
            persistent
            width="300"
            >
              <v-card
              color="primary"
              dark
              >
                <v-card-text>
                  {{loading.titulo}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    ></v-progress-linear>
                </v-card-text>
              </v-card>
            
          </v-dialog>

      </div>

       <div class="div_oculto" v-if="menulateral == true" @click="toggleMenu(false)" ></div>
       <bottomNav v-if="auth && bottomNav.disabled==false && bottomNav.items.length>0"/>

    </v-row>

    <Apierror v-if="pagstate=='errorapi'" />
    <UpdateAplicacion v-if="pagstate=='updateapk'" />

  </v-app>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import { gw } from '@/gw.js'
import ToolBar from '@/components/ToolBar'
import Breadcrumbs from '@/components/Breadcrumbs'
import MenuLateral from '@/components/MenuLateral';
import bottomNav from '@/components/bottomNav'
import Message from '@/components/Message'
import Apierror from '@/views/Apierror';
import UpdateAplicacion from '@/views/UpdateAplicacion';
import MenuLateralmovil from '@/components/MenuLateralmovil';

export default {
  name: 'App',
  mixins: [gw],
  components: {
    ToolBar,
    Breadcrumbs,
    MenuLateral,
    bottomNav,
    Apierror,
    UpdateAplicacion,
    MenuLateralmovil,
    Message
  }, 
  data: () => ({
     menulateral: process.env.VUE_APP_MENU_LATERAL
  }),
  computed:{
    ...mapState(['breadcrumbs','pagstate','message','routerviewkey']) //menulateral se carga en el componente MenuLateral
  },

  methods: {
    ...mapMutations(["setMenu"]),
      toggleMenu(action) {
      this.setMenu(action);
    },
  },
  created() { 
    //Control login
    if( (this.auth == undefined || this.auth == "" || this.auth == null) && (localStorage.getItem("auth")!=null) ){
      this.setAuth(JSON.parse(localStorage.getItem("auth")));
    }

    //bottomNav active 
    //if( (this.breadcrumbs.itemactive == undefined || this.breadcrumbs.itemactive == "" || this.breadcrumbs.itemactive == null) && (localStorage.getItem("bottomNavItemactive")!=null) ){
    // this.setbottomNav({itemactive: JSON.parse(localStorage.getItem("bottomNavItemactive"))});
    //}

    


    //this.apiPreciosCat(this.auth.cliente);
  },
  watch: {
        $route (to, from){

          //resetear bottomNav
          if(!this.bottomNav.views.includes(to.name) || !this.bottomNav.views.includes(from.name)){
            this.setbottomNav({
                  //itemactive: null,
                  disabled: true,
                  items: []
            });
          }
          this.setBreadcrumbs([]);
        }
    },
};
</script>

