<template>
    <v-navigation-drawer class="menu_escritorio">
      <v-list dense>
        <v-list-item>
          <v-list-item-title>
            <h2 class="headline grey--text">
              {{ $t("nivel1") }}
            </h2>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <div class="group-menu-list">
          <v-list-group
            value="true"
            append-icon="keyboard_arrow_down"
            no-action
            v-for="(nivel1, i) in menu"
            :key="i"
            v-model="nivel1.expand"
            @click="nivel1.expand = !nivel1.expand"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ nivel1.d }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <div class="group-menu-list-scroll">
              <v-list-item
                v-for="(nivel2, i) in nivel1.nivel2"
                :key="i"
                class="sublink"
                :to="'/articulos/' + nivel1.c + '/' + nivel2.c"
                style="min-height: 28px;"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="nivel2.d"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { gw } from "@/gw.js";
//- <tr :key="item.b" :class="item.stock <= 0 ? 'sin_stock' : ''">

export default {
  name: "MenuLateral",
  mixins: [gw],
  data: () => ({
    expcol: false,
    menu: [],
    men: false,
    articulos: []
  }),
  computed: {
    ...mapState(["menulateral"])
  },
  mounted() {
    this.cargarestructuramenu();
  },
  watch: {
    menulateral() {
      this.men = this.menulateral;
    }
  },
  methods: {
    ...mapMutations(["setMenu"]),
    toggleMenu(action) {
      this.setMenu(action);
    },
    addclass(){
      return "";
    },
    cargarestructuramenu() {
      let _this = this;
      this.api("/peticion/menuestructura", null).then(_return => {
        if (_return["success"] == "1") {
          _this.menu = _return["datos"]['nivel1'];
          _this.menu.forEach((e, i) => {
            e["expand"] = false;
          });
        }else if(_return["success"] == "2"){_this.toasted(resp.msg,"error","error");}
        else {
          //error..
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
#app{
    .contenido{
        width: calc(100% - 260px);// ANVCHO INCLUIENDO EL MENU LATERAL
        margin-left: 260px; //MARGEN PARA PODER PONER EL MENU LATERAL
    }
}
</style>