<template>  
        <v-layout>
          <v-flex sm-6>
            <div class="message">
              <v-icon :style="'color:'+message.coloricon">{{message.icon}}</v-icon>
              <p>{{message.text}}</p>

              <div class="button" v-for="(button, index) in message.buttons" :key="'buttonmessage'+index">
                 <div @click="click_btn(button)" v-if="button.text!=''">{{button.text}}</div>
              </div>

            </div>
          </v-flex>
        </v-layout>
</template>

<script>
 import { gw } from '@/gw.js'

export default {
    name: 'Message',
    mixins: [gw],
    mounted(){
      //
    },
    methods: {
      click_btn(button){
        this.message.active=false;
        this.to(button.to);
    }

    /*
    active:true,
    icon:'check_circle',
    coloricon:'#bd0909',
    text:'El PreAlbarán se ha creado correctamente, en breve recibirá un correo electronico con la confirmación.',
    buttons:[{
      text:'cerrar',
      to:''
    }]
    */
  }
}
</script>