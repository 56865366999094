<template>
      <v-navigation-drawer v-model="men" class="menu_movil" absolute top>
        <v-list dense>
          <v-list-item :to="'/cuenta'" @click="toggleMenu(false)">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="">person</v-icon>
                <span>{{ $t("toolbar_labperfil") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item :to="'/articulos'" v-if="tipo_view === 'ArticulosBotonera'" @click="toggleMenu(false)">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="">import_contacts</v-icon>
                <span>{{ $t("toolbar_articulos") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item :to="'/carro'" @click="toggleMenu(false)">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="mybtn" :class="(active_view === 'carro') ? 'black--text' : 'grey--text'">shopping_cart</v-icon>
                <span v-if="cantTotalCarro > 0" class="_badge">{{cantTotalCarro}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-divider v-if="tipo_view === 'ArticulosLateral'"></v-divider> -->
          <v-divider></v-divider>

          <!-- <div class="group-menu-list" v-if="tipo_view === 'ArticulosLateral'"> -->
          <div class="group-menu-list">
            <v-list-group
              value="true"
              append-icon="keyboard_arrow_down"
              no-action
              v-for="(nivel1, i) in menu"
              :key="i"
              v-model="nivel1.expand"
              @click="nivel1.expand = !nivel1.expand"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ nivel1.d }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <div class="group-menu-list-scroll">
                <v-list-item
                  v-for="(n2, i) in nivel1.nivel2"
                  :key="i"
                  class="sublink"
                  :to="'/articulos/' + nivel1.c + '/' + n2.c"
                  @click="toggleMenu(false)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="n2.d"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
        </v-list>
      </v-navigation-drawer>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { gw } from "@/gw.js";
//- <tr :key="item.b" :class="item.stock <= 0 ? 'sin_stock' : ''">

export default {
  name: "MenuLateralmovil",
  mixins: [gw],
  data: () => ({
    expcol: false,
    menu: [],
    men: false,
    articulos: [],
    //tipo_view: process.env.VUE_APP_TIPO_MENU
  }),
  computed: {
    ...mapState(["menulateral"])
  },
  mounted() {
    this.cargarestructuramenu();
  },
  watch: {
    menulateral() {
      this.men = this.menulateral;
    }
  },
  methods: {
    ...mapMutations(["setMenu"]),
    toggleMenu(action) {
      this.setMenu(action);
    },
    addclass(){
      return "";
    },
    cargarestructuramenu() {
      let _this = this;
      this.api("/peticion/menuestructura", null).then(_return => {
        if (_return["success"] == "1") {
          _this.menu = _return["datos"]['nivel1'];
          _this.menu.forEach((e, i) => {
            e["expand"] = false;
          });
        }else if(_return["success"] == "2"){_this.toasted(resp.msg,"error","error");}
        else {
          //error..
        }
      });
    },
    handlefunction(codnivel1, codnivel2) {
      let post = { codnivel1: codnivel1, codnivel2: codnivel2 };
      let _this = this;
      this.api("/peticion/articulos", post).then(_return => {
        if (_return["success"] == "1") {
          _return["datos"].forEach((d, index) => {
            if (
              d.img == "" ||
              d.img == "nofoto" ||
              d.img == "nofoto.jpg" ||
              d.img == "nofoto.png"
            ) {
              _return["datos"][index]["img"] =
                process.env.VUE_APP_IMG_URL + "nofoto.png";
            } else {
              _return["datos"][index]["img"] =
                process.env.VUE_APP_IMG_URL +
                "min/" +
                _return["datos"][index]["img"];
            }
          });

          this.articulos = _return["datos"];
        }else if(_return["success"] == "2"){_this.toasted(resp.msg,"error","error");}
        else {
          //error..
        }
      });
    },
    cargararticulos() {
      let _this = this;
      this.api("/peticion/menuestructura/ultimos", null).then(_return => {
        if (_return["success"] == "1") {
          _this.articulos = _return["datos"];
        }else if(_return["success"] == "2"){_this.toasted(resp.msg,"error","error");}
        else {
          //error..
        }
      });
    }
  }
};
</script>
