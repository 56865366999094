import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"app"}},[(_vm.auth && _vm.pagstate=='200')?_c(VAppBar,{attrs:{"app":""}},[_c('ToolBar')],1):_vm._e(),(_vm.pagstate=='200')?_c(VRow,{attrs:{"no-gutters":""}},[(_vm.auth && _vm.menulateral=='true')?_c('MenuLateral'):_vm._e(),(_vm.auth && _vm.menulateral=='true')?_c('MenuLateralmovil'):_vm._e(),_c('div',{staticClass:"contenido"},[(_vm.message.active)?_c('Message'):_vm._e(),_c('Breadcrumbs'),_c('router-view',{key:_vm.routerviewkey}),(_vm.loading.estado)?_c('div',{staticClass:"pantallacompletabloquo"}):_vm._e(),_c(VDialog,{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading.estado),callback:function ($$v) {_vm.$set(_vm.loading, "estado", $$v)},expression:"loading.estado"}},[_c(VCard,{attrs:{"color":"primary","dark":""}},[_c(VCardText,[_vm._v(" "+_vm._s(_vm.loading.titulo)+" "),_c(VProgressLinear,{attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),(_vm.menulateral == true)?_c('div',{staticClass:"div_oculto",on:{"click":function($event){return _vm.toggleMenu(false)}}}):_vm._e(),(_vm.auth && _vm.bottomNav.disabled==false && _vm.bottomNav.items.length>0)?_c('bottomNav'):_vm._e()],1):_vm._e(),(_vm.pagstate=='errorapi')?_c('Apierror'):_vm._e(),(_vm.pagstate=='updateapk')?_c('UpdateAplicacion'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }